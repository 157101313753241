<template>
  <div style="margin: 20px">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-card class="box-card" shadow="hover">
          <div style="
              display: flex;
              align-items: center;
              text-align: center;
              padding-left: 20px;
            ">
            <i class="el-icon-user-solid border" style="
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background: #8be9fd;
                color: white;"></i>
            <div style="margin-left: 20px">
              <h4 style="margin-bottom: 10px;color:red;">{{ topList.exGoodsRes.num }}</h4>
              <small>商品负库存数</small>
              &nbsp;
              <el-button type="danger" size="mini" @click="getExGoodsData">查看</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <div style="margin-top:10px;">
      <el-table :data="topList.exGoodsRes.tableData" height="600" border style="width: 100%">
        <el-table-column prop="item_no" label="sku" width="180" align="center">
        </el-table-column>
        <el-table-column prop="stock_num" label="负数" width="180" align="center">
        </el-table-column>
         <el-table-column prop="item_name" label="商品名称" align="center">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// import echarts from "echarts";

export default {
  data() {
    return {
      name: "",
      topList: {
        exGoodsRes: {
          num: 0,
          tableData: [],
        },
      },
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
    };
  },
  created() {},
  //动态加载完成之后
  mounted() {
    //画统计图
    // this.drawLine();
    this.getCumUser();
  },
  methods: {
    // drawLine() {
    //   let option = {
    //     title: {
    //       text: "产品人气榜",
    //     },
    //     tooltip: {},
    //   };
    //   var myChart = echarts.init(this.$refs.myChart);
    //   myChart.setOption(option);
    // },
    // 获取到有多少个 负库存的数据
    getCumUser() {
      this.$axios.get(`/a1/home/goods/noStock`).then((res) => {
        this.topList.exGoodsRes.num = res.data.data;
      });
    },
    // 获取到异常商品的数据
    getExGoodsData() {
      this.$axios.get(`/a1/home/goods/noStock/all`).then((res) => {
        this.topList.exGoodsRes.tableData = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
